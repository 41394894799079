import React, { Component } from "react";

export default class About extends Component {
  render() {
    return (
      <div className="flex-grow">
        <div className="w-full max-w-screen-xl mx-auto p-4">
          <h2 className="text-2xl font-semibold mb-4">À propos</h2>
          <p>
            Notre plateforme SmoFR conçue pour centraliser et organiser toutes
            les ressources clés liées à nos projets et initiatives en matière de
            mobilité. Vous y trouverez un répertoire complet de liens et de
            documentations, couvrant des sujets allant des tableaux de bord
            analytiques et des APIs aux sites web spécialisés. Que vous soyez à
            la recherche de données pour un nouveau projet, d'informations sur
            les API disponibles, ou de directives pour utiliser nos tableaux de
            bord internes, cette plateforme est votre point de référence pour
            accéder rapidement et efficacement à ces ressources.
          </p>
        </div>
      </div>
    );
  }
}

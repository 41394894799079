import React, { Component } from "react";

export default class Contact extends Component {
  render() {
    return (
      <div class="flex-grow">
        <div className="w-full max-w-screen-xl mx-auto p-4">
          <h2 class="text-2xl font-semibold mb-4">Contact</h2>
          <address>
            <a href="mailto:bastien.chardonnens@fr.ch">Bastien Chardonnens</a>
            <br></br>
            <br></br>
            Service de la mobilité<br></br>
            Grand-Rue 32<br></br>
            1701 Fribourg
          </address>
        </div>
      </div>
    );
  }
}

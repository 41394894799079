import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import About from "./components/About";
import Contact from "./components/Contact";
import Docu from "./components/Docu";
import CardReader from "./utils/CardReader";

const App = () => {
  return (
    <Router>
      <div className="bg-slate-50 overflow-y-scroll h-screen flex flex-col">
        <Header />
        <Routes>
          <Route path="/" element={<CardReader />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/doc/:doc?" element={<Docu />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import 'github-markdown-css/github-markdown-light.css'; // Utilisez le thème clair

function Docu() {
  const { doc } = useParams();
  const [markdown, setMarkdown] = useState('');

  useEffect(() => {
    fetch(`/docu/${doc}`)
      .then((response) => response.text())
      .then(setMarkdown);
  }, [doc]);

  return (
    <div className="flex-grow p-4">
      <div className="w-full max-w-screen-xl mx-auto">
        <div className="markdown-body"style={{ backgroundColor: '#f8fafc' }}>
          <ReactMarkdown remarkPlugins={[gfm]} children={markdown} />
        </div>
      </div>
    </div>
  );
}

export default Docu;
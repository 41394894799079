import React, { useState, useEffect } from "react";
import Card from "../components/Card";

const CardReader = () => {
  const [applink, setData] = useState([]);
  const [filterTag, setFilterTag] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dataFile =
          process.env.REACT_APP_DATA_TYPE === "interne"
            ? "data_interne.json"
            : "data.json";
        const response = await fetch(dataFile);
        const jsonData = await response.json();
        setData(jsonData.app);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des données JSON:",
          error
        );
      }
    };

    const savedFilterTag = localStorage.getItem("filterTag");
    if (savedFilterTag) {
      setFilterTag(savedFilterTag);
    }

    fetchData();
  }, []);

  const handleFilterChange = (e) => {
    const value = e.target.value;
    setFilterTag(value);
    localStorage.setItem("filterTag", value);
  };

  const filteredData = filterTag
    ? applink.filter((item) => item.tag.includes(filterTag))
    : applink;

  return (
    <div className="flex-1 w-full max-w-screen-xl mx-auto p-4">
      <div className="mb-4 flex justify-center">
        <select
          className="text-sm text-gray-500 sm:text-center dark:text-gray-400"
          value={filterTag}
          onChange={handleFilterChange}
        >
          <option value="">Filtre désactivé</option>
          <option value="dashboard">Dashboard</option>
          <option value="api">API</option>
          <option value="application">Application</option>
          <option value="website">Site web</option>
        </select>
      </div>

      <div className="p-0 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-5">
        {filteredData.map((item, index) => (
          <Card
            image={item.image}
            title={item.title}
            description={item.description}
            link={item.link}
            tags={item.tag}
            doc={item.doc}
            key={index}
          />
        ))}
      </div>
    </div>
  );
};

export default CardReader;
